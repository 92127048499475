import { loadState, saveState } from './localStorage';
import { configureStore } from '@reduxjs/toolkit';

import { audioApi } from './api/audioApi';

import { authApi } from './api/authApi';
import authReducer from './reducers/authReducer';
import { unauthenticate } from './reducers/authReducer';

import { statsApi } from './api/statsApi';

import { aiChatApi } from './api/aiChatApi';
import { qaChatApi } from './api/qaChatApi';

import { asteriskApi } from './api/asteriskApi';

const preloadedState = {
  auth: loadState('auth') || undefined,
};

const handle401Middleware = store => next => action => {
  if (action.error && action?.error?.message === 'Rejected') {
    store.dispatch(unauthenticate());
  }
  return next(action);
};

const localStorageMiddleware = store => next => action => {
  let result = next(action);
  saveState('auth', store.getState().auth);
  return result;
};

const middleware = [
  handle401Middleware,
  localStorageMiddleware,
  authApi.middleware, 
  audioApi.middleware, 
  statsApi.middleware, 
  aiChatApi.middleware, 
  qaChatApi.middleware, 
  asteriskApi.middleware,
];

const store = configureStore({
  reducer: {
    auth: authReducer,

    [authApi.reducerPath]: authApi.reducer,
    [audioApi.reducerPath]: audioApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
    [aiChatApi.reducerPath]: aiChatApi.reducer,
    [qaChatApi.reducerPath]: qaChatApi.reducer,
    [asteriskApi.reducerPath]: asteriskApi.reducer,
    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  preloadedState,
});

export default store;
