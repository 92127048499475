import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Menu, Space, Select, Avatar, Button, Popover, Typography, Radio, Tooltip, Row, Col, Card, Modal, List, Switch, Empty, Tabs, Divider, message } from 'antd';
import { DislikeOutlined, LikeOutlined, FileTextOutlined, ReadOutlined, DownloadOutlined, DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';
import { AudioPlayer } from '../AudioPlayer';
import ReactPlayer from 'react-player'
import { useGetAudioRecordTranscriptionQuery, useGetCustomFileTranscriptionsQuery } from '../../utils/api/audioApi';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';
import { useTranslation } from 'react-i18next';
import './DemoAudioPage.css'
import dayjs from 'dayjs';
import { loadState } from '../../utils/localStorage';
import { saveAs } from 'file-saver';


const BASE_CUSTOM_AUDIO_URL = process.env.REACT_APP_BASE_CUSTOM_AUDIO_URL;
const BASE_VIDEO_URL = process.env.REACT_APP_BASE_VIDEO_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Title, Text } = Typography;

const DemoAudioPage = ({call_data, callId, visible, onCancel }) => {
  const { t } = useTranslation()
  const [showButton, setShowButton] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentTranscriptionIndex, setCurrentTranscriptionIndex] = useState(0);
  const [currentTranscriptionId, setCurrentTranscriptionId] = useState();
  const [transcriptionDetailsVisible, setTranscriptionDetailsVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [startTime, setStartTime] = useState(0.0);
  const audioPlayerRef = useRef(null); 
  const trDataRef = useRef([]);
  const trDurationRef = useRef(0);
  const titleRef = useRef(null);
  
  const { data: transcriptionsData, isFetching: isFetchingTranscriptionsData, isSuccess: isSuccessTranscriptionsData, refetchTranscriptionsData } = useGetCustomFileTranscriptionsQuery({
    callId: call_data?.id,
  });

  useEffect(() => {
    if(isSuccessTranscriptionsData && !isFetchingTranscriptionsData && transcriptionsData && transcriptionsData?.transcriptions?.length > 0){
      setCurrentTranscriptionId(transcriptionsData?.transcriptions?.[currentTranscriptionIndex]?.id);
      trDataRef.current = transcriptionsData?.transcriptions?.[currentTranscriptionIndex]?.transcription_result?.map((item, index) => {
        return {
          ...item,
          timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
          className: "list-item"
        }
      });

      const fData = trDataRef.current?.filter(item => parseInt(item.start) <= parseInt(trDurationRef.current));
          const revFdata = fData?.reverse().map((item, index) => {
            return {
              ...item,
              timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
              className: parseInt(item.start)<=parseInt(trDurationRef.current) && parseInt(item.end)>=parseInt(trDurationRef.current) ? "list-item active-list-item" : item.className,
            }
          });
      setFilteredData(revFdata || []);

    }
  }, [transcriptionsData, currentTranscriptionIndex]);

  useEffect(() => {
    if (!visible) {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.handleStop();
        audioPlayerRef.current.handleUnmount();
        audioPlayerRef.current = null;
      }
    }
  }, [visible]);

  useEffect(() => {
    if(call_data){
      setStartTime(1.0*(call_data.wait_time+call_data.ivr_time)/call_data.duration);
    }
  }, [call_data]);


  const handleCurrentDurationChange = (duration) => {

      if(parseInt(trDurationRef.current) !== parseInt(duration)){
        trDurationRef.current = parseInt(duration);
        if(trDataRef.current){
          const fData = trDataRef.current?.filter(item => parseInt(item.start) <= parseInt(duration));
          const revFdata = fData?.reverse().map((item, index) => {
            return {
              ...item,
              timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
              className: parseInt(item.start)<=parseInt(duration) && parseInt(item.end)>=parseInt(duration) ? "list-item active-list-item" : item.className,
            }
          });
            setFilteredData(revFdata || []);
        }
      }
  };

  const transcriptionToText = (transcriptionId) => {
    const transcription = transcriptionsData?.transcriptions?.find(item => item?.id === transcriptionId)?.transcription_result;
    const txt = transcription?.map((item) => {
      return `${secondsToTimeFormat(item?.start)}-${secondsToTimeFormat(item?.end)} ${item?.speaker}:${item?.text}`
    }).join('\n');
    return txt;
  }

  const exportTranscription = (transcriptionId, save) => {
    if(!transcriptionId) return message.error(t('There is no available transcription'));
    const txt = transcriptionToText(transcriptionId);
    const blob = new Blob([txt], {type: "text/plain;charset=utf-8"});
    const url = URL.createObjectURL(blob);
    if(!save){saveAs(blob, `callId-${callId}_trid-${transcriptionId}.txt`)}
    if(save){return window.open(url, '_blank')}
  }

  const speakerColor = {
    '00': '#f56a00',
    '01': '#1931a8',
    '02': '#005e19',
  }

  return (
    <>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={"90%"}
        bodyStyle={{ minHeight: '92vh', maxHeight: '92vh', overflowY: 'hidden', overflowX: "hidden" }}
        style={{ top: "20px" }}
      >
        <>
          <AudioPlayer
            ref={audioPlayerRef}
            id={callId}
            key={callId}
            startTime={startTime}
            audioUrl={`${BASE_CUSTOM_AUDIO_URL}${callId}`}
            onError={(err) => { console.log(err); }}
            playPause={false}
            playAfterLoad={true}
            onIsPlayingChange={() => { }}
            onLoadingChange={() => { }}
            onCurrentDurationChange={handleCurrentDurationChange}
            token={loadState('auth').access}
            modalIsVisible={visible}
          />
          <Row>
            <Col span={16} style={{paddingRight: "16px"}} >
              <Space align='baseline' direction='horizontal' style={{ textJustify: 'center' }}>
              <Title 
                level={4} 
                >
                  {t("Transcription")}: {currentTranscriptionId}
              </Title>
              <Button icon={<DownloadOutlined />} 
                onClick={() => exportTranscription(currentTranscriptionId, false)}
                type='primary'
                size='small'
              />
              <Button icon={<FileTextOutlined />} 
                onClick={() => exportTranscription(currentTranscriptionId, true)}
                type='primary'
                size='small'
              />
              <Switch size='small' onChange={setShowButton} unCheckedChildren={t("Show full transcription")} checkedChildren={t("Show linked to timecode")}></Switch>
              
              </Space>
              <Tabs
                style={{margin:'2px'}}
                size='small'
                centered={true}
                defaultActiveKey="0"
                items={transcriptionsData?.transcriptions?.map((item, index) => {
                  return {
                    label: `Tr-${item.id}`,
                    key: index,
                  };
                })}
                onChange={(key) => {
                  setCurrentTranscriptionIndex(key);
                }}
                
              />
              
              <div
                id="scrollableDiv"
                style={{
                  maxHeight: '65vh',
                  minHeight: '65vh',
                  overflow: 'auto',
                  padding: '10 0',
                }}
              >
                {/* {(!showButton ? filteredData?.length > 0 : trDataRef?.current?.length > 0) && */}
                  <List
                    size='middle'
                    locale={transcriptionsData?.transcriptions[currentTranscriptionIndex]?.id
                      ? {emptyText: t('Wait for timestamp with transcription')}
                      : {emptyText: t('There is no available transcription')} 
                    }
                    loading={isFetchingTranscriptionsData}
                    dataSource={!showButton ? filteredData : trDataRef?.current}
                    renderItem={(item, index) => (
                    <List.Item ref={titleRef} className={item?.className} actions={[
                        // <Checkbox 
                        //   autoFocus={false}
                        //   onChange={(e) => {
                        //     titleRef.current.focus();
                        //   }}
                        //   onKeyDown={(e) => {
                        //     e.preventDefault();
                        //   }}
                        //   >
                        //     Accept
                        //   </Checkbox>, 
                        // <DislikeOutlined/>
                        ]}>
                      <List.Item.Meta
                        avatar={<Avatar style={{color: speakerColor[typeof item?.speaker === "string" ? item?.speaker?.slice(-2) : "00"]}} /*icon={<UserOutlined/>}*/>{typeof item?.speaker === "string" ? item?.speaker?.slice(-2) : "-"}</Avatar>}
                        description={<Button type='link' onClick={() => {audioPlayerRef.current.goTo(item.start)}}>{item?.timecode}</Button>}
                      />
                      {item.text}
                    </List.Item>
                  )}
                  />
                    {/* } */}
              </div>
            </Col>
          </Row>
            
          
          </>
      </Modal>
    </>
  )
}

export default DemoAudioPage;
