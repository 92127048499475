import React, { useRef } from 'react'

import {
    ChakraProvider,
    Box,
    Flex,
    Heading,
    Text,
    Button,
    VStack,
    HStack,
    Icon,
    StackProps,
    Stack,
    useColorModeValue,
    Link,
    LinkOverlay,
    Image
  } from '@chakra-ui/react';
  import { Fade, ScaleFade, Slide, SlideFade, Collapse } from '@chakra-ui/react'

const LandingSpeechToText = () => {
  return (
    <>
        <Heading textAlign="center" fontSize="3xl" mb={10}>
        Accurately transcribe calls and distinguish between different speakers for clear, actionable insights.
        </Heading>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          minH="50vh"
          m={10}
          border="1px solid"
          borderColor="gray.200"
          borderRadius={10}
          p={10}
        >
        <video 
          src="./stt.mp4" 
          alt="stt" 
          style={{maxWidth: "50%"}} 
          autoPlay
          loop
          muted
          controls
          />
        {/* <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone> */}
        </Box>
    </>
  )
}

export default LandingSpeechToText