import {
    createApi,
    fetchBaseQuery,
  } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { loadState } from '../localStorage';
import { message } from 'antd';
  
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const mutex = new Mutex();
  
  const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL, credentials:"include"});
  
  const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if(result?.error?.status === "FETCH_ERROR"){
      message.error("Server is down, please try again later");
    };
    if (result.error && result.error.status === 401) {
      if(result.error.status === 401){
        message.error(result.error.data.detail);
      };
      
      


    //   console.log('401 error, trying to refresh token');
    //   if (!mutex.isLocked()) {
    //     const release = await mutex.acquire();
    //     try {
    //       const refreshResult = await api.dispatch(authApi.endpoints.refreshToken.initiate());
    //       if (refreshResult.data) {
    //         api.dispatch(authApi.endpoints.refreshToken.fulfilled(refreshResult.data));
    //         result = await baseQuery(args, api, extraOptions);
    //       } else {
    //         api.dispatch(authApi.endpoints.logout());
    //       }
    //     } finally {
    //       release();
    //     }
    //   } else {
    //     await mutex.waitForUnlock();
    //     result = await baseQuery(args, api, extraOptions);
    //   }
    }
    return result;
  };
  
  export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    timeout: 5000,
      onQueryError: (error) => {
        console.error('An error occurred:', error);
      },
    endpoints: (builder) => ({
      getToken: builder.mutation({
        query: ({username, password, recaptchaToken}) => ({
          url: 'token_custom/pair_custom',
          method: 'POST',
          body: {
            username,
            password,
            recaptcha: recaptchaToken,  
          },
        }),
        transformResponse: (response) => {
          return response;
        },
      }),
      refreshToken: builder.mutation({
        query: () => ({
          url: 'token/refresh',
          method: 'POST',
          body: { refresh: loadState('auth').refresh },
        }),
      }),
      verifyToken: builder.mutation({
        query: (token) => ({
          url: 'token/verify',
          method: 'POST',
          body: { token: token },
        }),
      }),
      accessRequest: builder.mutation({
        query: ({permission}) => ({
          url: 'stt/stt_access_request',
          method: 'POST',
          // credentials: 'include',
          // headers: { "Authorization": `Bearer ${loadState('auth').access}`},
          body: { permission: permission },
        }),
      }),
    }),
  });
  
  export const {
    useGetTokenMutation,
    useRefreshTokenMutation,
    useVerifyTokenMutation,
    useAccessRequestMutation,
    endpoints,
  } = authApi;