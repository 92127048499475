import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const BASE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL

class WebSocketManager {
  constructor() {
    this.handlers = [];
    this.ws = null;
    this.reconnectDelay = 5000;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.url = null;
  }

  connect(url) {
    if (this.ws && this.url === url) {
      return;
    }
    this.url = url;
    this.close();
    this.ws = new WebSocket(this.url);
    this.ws.onopen = () => {
      this.reconnectAttempts = 0; 
    };
    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.handlers.forEach(handler => handler(data));
    };
    this.ws.onclose = (event) => {
      if (event.code === 1000) {
          return;  // Exit the handler early, no reconnection attempt
      }
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        setTimeout(() => this.reconnect(), this.reconnectDelay * Math.pow(2, this.reconnectAttempts));
      }
    };
    this.ws.onerror = (error) => {
      console.log("onerror", error);
      this.ws.close();
    };
  }

  reconnect() {
    if (this.url) {
      this.close();
      this.connect(this.url);
    }
  }

  registerHandler(handler) {
    this.handlers.push(handler);
  }

  unregisterHandler(handler) {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  sendMessage(message) {
    this.ws?.send(JSON.stringify(message));
  }

  close() {
    this.ws?.close();
    this.ws = null;
  }
}

const wsManager = new WebSocketManager();

function chatMessageHandler(data, updateCachedData) {

  updateCachedData((draft) => {
    const existingMessageIndex = draft?.messages?.findIndex(message => message.id === data?.message?.id)
    if(data?.type==="chat.message"){
      draft?.messages?.push(data?.message)
    }
    if(data?.type==="chat.message.append"){
      if (existingMessageIndex > -1) {
        draft.messages[existingMessageIndex].message += data?.message?.message
      }
    }
    if(data?.type==="chat.message.update"){
      if (existingMessageIndex > -1) {
        draft.messages[existingMessageIndex].message = data?.message?.message
      }
    }
  });
}



export const qaChatApi = createApi({
  reducerPath: 'qaChatApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${BASE_URL}/qachat`,
    credentials:"include",
  }),
  endpoints: (build) => ({
    createQaChat: build.mutation({
        query: (transcription_id) => ({
            url: `/chat/create`,
            method: 'POST',
            body: {transcription_id},
        }),
        keepUnusedDataFor: 0,
      }),
    getQaChat: build.query({
        query: (transcription_id) => ({
          url: `chat/${transcription_id}`,
          method: 'GET',
        }),
        keepUnusedDataFor: 1,
        transformResponse: (response) => {
          return response
        },
        async onCacheEntryAdded(chatUuid, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {

          const handler = (data) => chatMessageHandler(data, updateCachedData);
      
          // Connect to the WebSocket and register the handler
          wsManager.connect(`${BASE_WS_URL}/aichat/${chatUuid}`);
          wsManager.registerHandler(handler);
      
          try {
            // Wait for the cache data to load
            await cacheDataLoaded;
          } catch {
            // If there's an error loading the cache data, unregister the handler
            wsManager.unregisterHandler(handler);
          }
      
          // Wait for the cache entry to be removed
          await cacheEntryRemoved;
      
          // Unregister the handler when the cache entry is removed
          wsManager.unregisterHandler(handler);
        },
      }),
    sendQaWebSocketMessage: build.mutation({
      queryFn: (message) => {
        wsManager.sendMessage(message);
        return { data: {} };
      },
    }),
  }),
})


export const { 
    useGetQaChatQuery, 
    useCreateQaChatMutation,
    useSendQaWebSocketMessageMutation,
    } = qaChatApi

