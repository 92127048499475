import React, { useState, useEffect } from 'react';
import { Web } from 'sip.js';

function getAudioElement(id) {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}

const UserPage = () => {
  const [simpleUser, setSimpleUser] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false);

  useEffect(() => {
    const server = 'wss://ai.adelina.com.ua/webrtc/aster';
    const aor = 'sip:webrtc_client_1002@10.24.2.121:5060';
    const destination = 'sip:1002@10.24.2.121:5060';
    const authorizationPassword= 'webrtc_client_1002';
    const authorizationUsername = 'webrtc_client_1002';
    const options = {
      aor,
      userAgentOptions: {
        
        displayName: 'webrtc_client_1002_name',
        authorizationPassword,
        authorizationUsername,
      },
      media: {
        remote: {
          audio: getAudioElement("remoteAudio"),
        },
      },
    };

    const user = new Web.SimpleUser(server, options);
    console.log(user);
    user.delegate = {
      onCallReceived: async () => {
        await user.answer();
        setIsCallActive(true);
      },
      onCallTerminated: () => {
        setIsCallActive(false);
      },
    };
    console.log(user);

    setSimpleUser(user);
  }, []);

  const makeCall = async () => {
    if (simpleUser) {
      await simpleUser.connect();
      await simpleUser.register();
      await simpleUser.call('sip:yzhdanov@10.24.2.121:5060');
      setIsCallActive(true);
    }
  };

  const hangUp = async () => {
    if (simpleUser && isCallActive) {
      await simpleUser.hangup();
      setIsCallActive(false);
    }
  };

  return (
    <div>
      <button onClick={makeCall}>Call</button>
      <button onClick={hangUp} disabled={!isCallActive}>Hang Up</button>
      <audio id="remoteAudio" autoPlay />
    </div>
  );
}

export default UserPage;
