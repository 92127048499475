import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const GetPresetRanges = () => {
    const { t } = useTranslation();
    const now = dayjs();
    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'day').startOf('day');
    const endOfYesterday = now.clone().subtract(1, 'day').endOf('day');
    const startOfSevenDays = now.clone().subtract(6, 'day').startOf('day');
    const endOfSevenDays = now.clone().endOf('day');
    const startOfWeek = now.clone().startOf('week');
    const endOfWeek = now.clone().endOf('week');
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');
    const startOfPrevWeek = now.clone().subtract(1, 'week').startOf('week');
    const endOfPrevWeek = now.clone().subtract(1, 'week').endOf('week');
    const startOfPrevMonth = now.clone().subtract(1, 'month').startOf('month');
    const endOfPrevMonth = now.clone().subtract(1, 'month').endOf('month');
  
    return [
      { label: t('Today'), value: [today, now] },
      { label: t('Yesterday'), value: [yesterday, endOfYesterday] },
      { label: t('Yesterday+today'), value: [yesterday, now] },
      { label: t('Last 7 days'), value: [startOfSevenDays, endOfSevenDays] },
      { label: t('Current week'), value: [startOfWeek, endOfWeek] },
      { label: t('Previous week'), value: [startOfPrevWeek, endOfPrevWeek] },
    ];
  };