import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
// import { PrivateRoute } from './PrivateRoute';
import PermissionRoute from './PermissionRoute';
import Page404 from './Page404';
import { LoginPage, HomePage, TranscriptionViewer, LiveTranscriptionsViewer, LiveTranscriptionsViewerBridge, UserPage, AudioPageDedicated, AiChat, DemoPage, DemoAiChat, LandingPage } from '../';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loadState } from '../../utils/localStorage';


const MainRouter = () => {
    const auth = loadState('auth');
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/' && auth?.username === 'demo1') {
            navigate('/demo_stt');
        }
        if (location.pathname === '/stt' && auth?.username === 'demo1') {
            navigate('/demo_stt');
        }
        if (location.pathname === '/aichat' && auth?.username === 'demo1') {
            navigate('/demo_aichat');
        }
    }, [auth, location, navigate]);
    return (
        <>
            <Routes>
              <Route exact path="/landing" element={<LandingPage/>}/>
              <Route exact path="/login" element={<LoginPage/>}/>
              <Route exact path="/stt" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><TranscriptionViewer /></PermissionRoute>} />
              <Route exact path="/live_stt" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><LiveTranscriptionsViewer /></PermissionRoute>} />
              <Route exact path="/live_suggestions" element={<LiveTranscriptionsViewerBridge />} />
              <Route exact path="/profile" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><UserPage /></PermissionRoute>} />
              <Route exact path="/stt/audiorecord/:marsid" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><AudioPageDedicated /></PermissionRoute>} />
              <Route exact path="/stt/:projid" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><TranscriptionViewer /></PermissionRoute>} />
              <Route exact path="/aichat" element={<PermissionRoute requiredPermission="aichat.view_aichat"><AiChat /></PermissionRoute>} />
              <Route exact path="/demo_stt" element={<DemoPage />} />
              <Route exact path="/demo_aichat" element={<DemoAiChat />} />
              <Route exact path="/" element={<PermissionRoute requiredPermission="stt_api.view_transcription"><HomePage /></PermissionRoute>}/>
              <Route exact path="*" element={<Page404/>} /> 
            </Routes>
        </>
    )
}

export default MainRouter