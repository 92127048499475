import React, { useEffect, useState } from 'react';
import { Modal, Card, Typography, Row, Col, Space, Descriptions, Divider } from 'antd';
import { useGetChannelsQuery, useGetSuggestionsQuery, useGetBridgesQuery } from '../../utils/api/asteriskApi';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ASTERISK_AUDIO_WS_URL = process.env.REACT_APP_ASTERISK_AUDIO_WS_URL;

const { Title } = Typography;

const LiveTranscriptionsViewer = () => {
    const [selectedChannel, setSelectedChannel] = useState();
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { data: bridgesList, isLoading: bridgesIsLoading, isError: bridgesIsError, error: bridgesError } = useGetBridgesQuery();
    const { data: channelList, isLoading, isError, error } = useGetChannelsQuery();
    const { data: channelData } = useGetSuggestionsQuery(selectedChannel, { skip: !selectedChannel });

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const getTimeDifference = (insertTime) => {
        const insertTimeDayjs = dayjs(insertTime * 1000);
        const differenceInMilliseconds = currentTime.diff(insertTimeDayjs);
        const differenceDuration = dayjs.duration(differenceInMilliseconds);
        const formattedDifference = differenceDuration.format('HH:mm:ss');
        return formattedDifference;
    };

    const handleChannelSelect = value => {
        setSelectedChannel(value);
        setIsModalVisible(true);
    };

    const handleChannelUnSelect = () => {
        setSelectedChannel(null);
        setIsModalVisible(false);
    };

    return (
        <Space direction='vertical' size={'small'} style={{ padding: '16px', width: '100%' }}>
            <Row justify='center'>
                <Title level={3}>Live Transcriptions</Title>
            </Row>
            <Row justify='center'>
                <Title level={4}>093 175 55 10 / 050 117 90 04</Title>
            </Row>
            <Row gutter={16} justify='center'>
                {bridgesList?.bridges?.map(bridge => (
                    <Col key={bridge?.bridge_id} style={{ width: 300 }}>
                        <Card
                            title={bridge?.bridge_id}
                            hoverable={true}
                        >
                            <Descriptions column={1}>
                                <Descriptions.Item label="Bridge ID"> {bridge.bridge_id}</Descriptions.Item>
                                <Descriptions.Item label="Bridge Type"> {bridge.bridge_type}</Descriptions.Item>
                                <Descriptions.Item label="Bridge Technology"> {bridge.bridge_technology}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row gutter={16} justify='center'>
                {channelList?.channels?.map(channel => (
                    <Col key={channel?.channel_id} style={{ width: 300 }}>
                        <Card
                            onClick={() => handleChannelSelect(channel?.caller_number)}
                            title={channel?.caller_number}
                            hoverable={true}
                        >
                            <Descriptions column={1}>
                                <Descriptions.Item label="Time Elapsed"> {getTimeDifference(channel.channel_id)}</Descriptions.Item>
                                <Descriptions.Item label="Channel ID"> {channel.channel_id}</Descriptions.Item>
                                <Descriptions.Item label="Context"> {channel.dialplan_name}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                ))}
            </Row>
            {selectedChannel &&
                <Modal
                    title={selectedChannel}
                    open={isModalVisible}
                    footer={null}
                    onCancel={handleChannelUnSelect}
                    width={'80%'}
                >
                    <Row>
                        <Descriptions column={1}>
                            <Descriptions.Item label="Transcription text">{channelData?.transcription}</Descriptions.Item>
                        </Descriptions>
                    </Row>
                    <Row>
                        {
                            channelData?.context_list?.map((row, index) => (
                                <>
                                <Divider key={index} />
                                <Row>
                                    <Col span={1}>
                                        {row?.distance.toFixed(2)}
                                    </Col>
                                    <Col span={23}>
                                        {row?.text}
                                    </Col>
                                </Row>
                                </>
                            ))
                        }
                    </Row>
                </Modal>
            }
        </Space>
    );
};

export default LiveTranscriptionsViewer;
