import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import AudioPage from './AudioPage';
import { 
    useGetAudioRecordsQuery, 
  } from '../../utils/api/audioApi';

const AudioPageDedicated = () => {
  const { marsid } = useParams();

  const { data, isFetching: isFetchingRecords, error, refetch, isSuccess } = useGetAudioRecordsQuery(
    {record_id: marsid, limit: 1, offset: 0}
  );
  
  return (
    <>
        {data && isSuccess &&
            <AudioPage
                call_data={data?.calls?.[0]}
                marsid={marsid}
                visible={true}
                key={marsid}
            />
        }
    </>
  );
};

export default AudioPageDedicated;
