import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Result, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAccessRequestMutation } from '../../utils/api/authApi';

const Page403 = ({ requiredPermission }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [buttonAvailable, setButtonAvailable] = useState(true);
  const [accessRequestMutation, accessRequestMutationResult] = useAccessRequestMutation();

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Error while sending access request, try again later.',
    });
  };

  const handleAccessRequestMutation = async () => {
    try {
        setButtonAvailable(false);
        const mutationResponse = await accessRequestMutation({permission: requiredPermission});
        if (mutationResponse?.data) {
            setResponse(mutationResponse?.data)
            }
    } catch (error) {
        setButtonAvailable(true);
        error();
    }
  };

  return (
    <>
    {contextHolder}
    
    <Result
      status="403"
      title="403"
      subTitle={t("Sorry, you are not authorized to access this page.")}
      extra={!response ?
            <Button disabled={!buttonAvailable} type="primary" onClick={handleAccessRequestMutation}>{t("Send request to get access")}</Button>
            :
            <a target="_blank" rel="noopener noreferrer" href={`https://support.adelina.com.ua/browse/${response?.key}`}> {t("Issue")}: {response?.key}</a>
        
            }
    />
    </>
  );
};

export default Page403;
