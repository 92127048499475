
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { en, ua, ro } from "./translations.js";


const resources = {
  en: {
    translation: en
  },
  ua: {
    translation: ua
  },
  ro: {
    translation: ro
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;