import React, { useEffect, useState } from 'react';
import { Modal, Card, Typography, Row, Col, Space, Radio, Image, Button, Form, Input, Collapse } from 'antd';
import {TranslationOutlined} from '@ant-design/icons';
import { useGetChannelsQuery, useGetSuggestionsQuery, useGetBridgesQuery, useMakeCallMutation, useGetBridgeConversationQuery } from '../../utils/api/asteriskApi';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ASTERISK_AUDIO_WS_URL = process.env.REACT_APP_ASTERISK_AUDIO_WS_URL;
const REACT_APP_BACK_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const { Title, Text } = Typography;

const LiveTranscriptionsViewerBridge = () => {
    const [form] = Form.useForm();
    const [n1, setN1] = useState();
    const [n2, setN2] = useState();
    const [lang, setLang] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState();
    const [selectedBridge, setSelectedBridge] = useState();
    const [conversation, setConversation] = useState([]);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { data: bridgesList, isLoading: bridgesIsLoading, isError: bridgesIsError, error: bridgesError } = useGetBridgesQuery();
    const { data: channelList, isLoading, isError, error } = useGetChannelsQuery();
    // const { data: channelData } = useGetSuggestionsQuery(selectedChannel, { skip: !selectedChannel });
    const { data: bridgeData } = useGetBridgeConversationQuery(selectedBridge, { skip: !selectedBridge });
    const [makeCall, {data: makeCallData, status}] = useMakeCallMutation();

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const getTimeDifference = (insertTime) => {
        const insertTimeDayjs = dayjs(insertTime * 1000);
        const differenceInMilliseconds = currentTime.diff(insertTimeDayjs);
        const differenceDuration = dayjs.duration(differenceInMilliseconds);
        const formattedDifference = differenceDuration.format('HH:mm:ss');
        return formattedDifference;
    };

    const handleBridgeSelect = value => {
        setSelectedBridge(value);
        setIsModalVisible(true);
    };

    const handleBridgeUnSelect = () => {
        setSelectedBridge(null);
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        // console.log('Received values:', values);
        makeCall({ n1: values.n1, n2: values.n2, language: values.language});

      };


      useEffect(() => {
        if (bridgeData) {
            const conv = bridgeData?.conversation ? [...bridgeData.conversation] : [];
            conv.sort((a, b) => b?.message_id - a?.message_id);

            // Extract unique channel_id values and sort them
            const uniqueChannelIds = [...new Set(conv.map(item => item.channel_id))].sort();

            // Check if there are at least two unique channel_ids
            if (uniqueChannelIds.length >= 2) {
                const [lowerValue, greaterValue] = uniqueChannelIds;

                // Create a new array with the "role" field based on the channel_id comparison
                const updatedConv = conv.map(item => {
                    return {
                        ...item,
                        role: item.channel_id === lowerValue ? "client" : "agent",
                        bg: item.channel_id === lowerValue ? "#dceaf7" : "#f0f0f0",
                    };
                });

                setConversation(updatedConv);
            } else {
                setConversation(conv);
            }
        }
    }, [bridgeData]);

    return (
        <Space direction='vertical' size={'small'} style={{ padding: '16px', width: '100%' }}>
            <Row justify='center'>
                <Title level={3}>Live Suggestions</Title>
            </Row>
            <Row justify='center'>
                
                <Form 
                    form={form} 
                    layout="inline" 
                    onFinish={onFinish}
                    initialValues={{ n1, n2 }}
                    // initialValues={{ 
                    //     n1: '0936628576', 
                    //     n2: '68888'
                    // }}
                >
                    <Form.Item
                        label="Language"
                        name="language"
                    >
                        <Radio.Group 
                            options={[
                                {label: "Ukrainian", value: "uk"},
                                {label: "French", value: "fr"},
                                ]} 
                            onChange={(e) => {
                                    setLang(e.target.value);
                                    // console.log(e.target.value);
                                    // console.log(lang);
                                }
                            } 
                            value={lang} 
                            optionType="button" />
                    </Form.Item>
                    <Form.Item
                        label="Client phone number"
                        name="n1"
                    >
                        <Input 
                            value={n1}
                            onChange={(e) => setN1(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Agent phone number"
                        name="n2"
                    >
                        <Input
                            value={n2}
                            onChange={(e) => setN2(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>

                            <Button type="primary" htmlType="submit" disabled={!lang}>
                                Call
                            </Button>

                    </Form.Item>
                </Form>
        
            </Row>
            <Row gutter={16} justify='center' style={{minHeight: "25vh"}}>
                
                {bridgesList?.bridges?.filter(item => item !== undefined)?.map(bridge => (
                    <Col key={bridge?.bridge_id} style={{ width: 300 }}>
                        <Card
                            title={bridge?.bridge_id}
                            hoverable={true}
                            onClick={() => handleBridgeSelect(bridge?.bridge_id)}
                        >
                            
                                {bridge?.channels?.map(channel => (
                                    <Row>
                                    <Col span={12}><Text type='secondary'>{channel?.caller_number}</Text></Col>
                                    <Col span={12}><Text strong type='primary' style={{color: channel?.status_color}}>{channel?.status}</Text></Col>
                                    </Row>
                                ))    
                                }
                        </Card>
                    </Col>
                ))}
            </Row>
            <Button onClick={() => setHistoryVisible(!historyVisible)}>Show History</Button>
            {historyVisible &&
            <Row gutter={16} justify='center'>
                {bridgesList?.history?.filter(item => item !== undefined)?.map(bridge => (
                    <Col key={bridge?.id} style={{ width: 300 }}>
                        <Card
                            title={bridge?.id}
                            hoverable={true}
                            onClick={() => handleBridgeSelect(bridge?.id)}
                        >
                            <Row>
                                <Text type='secondary'>{bridge?.created_at}</Text>
                            </Row>
                            {bridge?.channels?.map(channel => (
                                    <Row>
                                    <Col span={12}><Text type='secondary'>{channel?.caller_number}</Text></Col>
                                    <Col span={12}><Text strong type='primary' style={{color: channel?.status_color}}>{channel?.status}</Text></Col>
                                    </Row>
                                ))    
                                }
                        </Card>
                    </Col>
                ))}
            </Row>
            }
            
            {selectedBridge &&
                <Modal
                    title={selectedBridge}
                    open={isModalVisible}
                    footer={null}
                    onCancel={handleBridgeUnSelect}
                    width={'80%'}
                >
                    <Row>
                        {
                            conversation?.map((row, index) => (
                                <>
                                <Row style={{
                                    minWidth:"100%",
                                    
                                    }} key={index}>
                                <Collapse 
                                    size='small' 
                                    style={{
                                        minWidth:"100%", 
                                        backgroundColor: row?.bg,
                                        borderColor: row?.bg,
                                    }} 
                                    defaultActiveKey={['0']} >
                                    <Collapse.Panel 
                                        header={
                                            <Row>
                                                <Col span={2}>
                                                    {row?.role}
                                                </Col>
                                                <Col span={2}>
                                                    {row?.caller_number}
                                                </Col>
                                                <Col span={4}>
                                                    {row?.created_at}
                                                </Col>
                                                <Col span={16}>
                                                    {row?.text}
                                                </Col>
                                            </Row>
                                        } key={index}>
                                            <Row>
                                                <Col span={9}>
                                                    {row?.copilot_response?.summary}
                                                    <br/>
                                                    <TranslationOutlined />
                                                    <br/>
                                                    {row?.copilot_response?.summary_translation}
                                                </Col>
                                                <Col span={9}>
                                                    {row?.copilot_response?.suggestion}
                                                    <br/>
                                                    <TranslationOutlined />
                                                    <br/>
                                                    {row?.copilot_response?.suggestion_translation}
                                                </Col>
                                                <Col span={6}>
                                                    {
                                                        row?.copilot_response?.images?.map((image, index) => (
                                                            <Image 
                                                                key={`img_${index}`} 
                                                                height={100}
                                                                src={`${REACT_APP_BACK_BASE_URL}/stt/get_image/${image}`} 
                                                                alt="suggestion" />
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                    </Collapse.Panel>
                                </Collapse>
                                </Row>
                                </>
                            ))
                        }
                    </Row>
                </Modal>
            }
        </Space>
    );
};

export default LiveTranscriptionsViewerBridge;
