import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loadState } from '../localStorage';


const BASE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL

class WebSocketManager {
  constructor() {
    this.handlers = [];
    this.ws = null;
    this.reconnectDelay = 5000;
    this.url = null;
  }

  connect(url) {
    if (!this.ws) {
      this.url = url;
      this.ws = new WebSocket(this.url);
      this.ws.onopen = () => {
        this.ws.send(JSON.stringify({ ping: null }));
      };
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.handlers.forEach(handler => handler(data));
      };
      this.ws.onclose = (event) => {
        // console.log("WebSocket connection closed. Attempting to reconnect...");
        setTimeout(() => this.reconnect(), this.reconnectDelay);
      };
    }
  }

  reconnect() {
    if (this.url) {
      this.close();
      this.connect(this.url);
    }
  }

  registerHandler(handler) {
    this.handlers.push(handler);
  }

  unregisterHandler(handler) {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  sendMessage(message) {
    this.ws?.send(JSON.stringify(message));
  }

  close() {
    this.ws?.close();
    this.ws = null;
  }
}

const wsManager = new WebSocketManager();

function userMessageHandler(data, updateCachedData) {
  updateCachedData((draft) => {
    const existingFileIndex = draft?.files?.findIndex(file => file.id === data?.message?.file_id)
    if(draft){
      if(data?.type==="user.channel.message"){
        if (existingFileIndex > -1) {
          draft.files[existingFileIndex].duration_transcribed = data?.message?.current_time
        }
      };
    };
  });
  
}


export const audioApi = createApi({
  reducerPath: 'audioApi',
  baseQuery: fetchBaseQuery({ 
          baseUrl: BASE_URL,
          credentials:"include",
          // mode: 'cors',
          prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${loadState('auth').access}`);
            return headers;
          }
        }), 
  endpoints: (builder) => ({
    getAudioRecords: builder.query({
      query: (params) => {
        if(params){
          const recordIdParam = params?.record_id ? `&marsid=${params?.record_id}` : '';
          const pilotParam = params?.pilot ? `&pilot=${params?.pilot}` : '';
          const projidParam = params?.projid ? `&projid=${params?.projid}` : '';
          const startDatetime = params?.call_date_start ? `&call_date_start=${params?.call_date_start}` : '';
          const endDatetime = params?.call_date_end ? `&call_date_end=${params?.call_date_end}` : '';
          const phoneParam = params?.phone ? `&phone=${params?.phone}` : '';
          const agentParam = params?.agent ? `&agent=${params?.agent}` : '';
          const haveTranscription = params?.have_transcription ? `&have_transcription=${params?.have_transcription}` : '';
          const calledNumber = params?.phone ? `&phone=${params?.phone}` : '';
          const accessCodeParam = params?.access_code ? `&access_code=${params?.access_code}` : '';
          const limitParam = `&limit=${params?.limit}`;
          const offsetParam = `&offset=${params?.offset}`;
          const orderByParam = params?.order_by ? `&order_by=${params?.order_by}` : '';
          return `/stt/calls?${startDatetime}${endDatetime}${limitParam}${offsetParam}${projidParam}${calledNumber}${pilotParam}${accessCodeParam}${phoneParam}${orderByParam}${recordIdParam}${agentParam}${haveTranscription}`
        }
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
      transformResponse: (response) => {
        const callsWithKeys = response.calls.map((call, index) => ({
          ...call,
          key: index.toString(),
        }));
        return { ...response, calls: callsWithKeys };
      },
    }),
    getAudioRecordTranscription: builder.query({
      query: (params) => {
        if(params?.marsid){
          const recordId = params?.marsid ? `&marsid=${params?.marsid}` : '';
          return `/stt/transcription?${recordId}`
        }
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getAudioRecordTranscriptions: builder.query({
      query: (params) => {
        if(params?.callId){
          const callIdParam = params?.callId ? `&call_id=${params?.callId}` : '';
          return `/stt/transcriptions?${callIdParam}`
        }
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getProjects: builder.query({
      query: () => ({
        url: '/stt/projects',
        method: 'GET'
      }),
      transformResponse: (response) => {
        
        const res = response.map((project) => ({
          ...project,
          // disabled: project.projid !== 2052 ? true : false,
          disabled: [2052, 2664].includes(project.projid) ? false : true,
        }));
        return res;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getPilots: builder.query({
      query: (params) => {
        const projIdParam = params?.projid ? `projid=${params?.projid}` : '';
        return `/stt/pilots?${projIdParam}`;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getAccessCodes: builder.query({
      query: (params) => {
        const projIdParam = params?.projid ? `&projid=${params?.projid}` : '';
        return `/stt/acess_codes?${projIdParam}`;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getAgents: builder.query({
      query: (params) => {
        const projIdParam = params?.projid ? `&projid=${params?.projid}` : '';
        return `/stt/agents?${projIdParam}`;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    getStatistics: builder.query({
      query: () => {
        return `/stt/statistics`;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
    }),
    addMessage: builder.mutation({
      query: (params) => ({
        url: '/create_message', 
        method: 'POST',
        body: params,
      }),
    }),
    getUserFilesList: builder.query({
      query: () => {
        return `/stt/get_audio_list`;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: false,
      transformResponse: (response) => {
        const filesWithKeys = response?.files?.map((r, index) => ({
          ...r,
          key: r.id.toString(),
        }));
        return { ...response, files: filesWithKeys };
      },
      async onCacheEntryAdded(
        chatUuid,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        wsManager.connect(`${BASE_WS_URL}/userchannel/${loadState('auth').username}`);
        wsManager.registerHandler((data) => userMessageHandler(data, updateCachedData));
        try {
          await cacheDataLoaded;
        } catch {
          wsManager.unregisterHandler((data) => userMessageHandler(data, updateCachedData));
        }
        await cacheEntryRemoved;
        wsManager.unregisterHandler((data) => userMessageHandler(data, updateCachedData));
      },
    }),
    deleteUserFile: builder.mutation({
      query: (file_id) => ({
        url: `/stt/delete_audio/${file_id}`, 
        method: 'DELETE',
      }),
    }),
    transcribeUserFile: builder.mutation({
      query: (file_id) => ({
        url: `/stt/transcribe_audio/${file_id}`, 
        method: 'POST',
      }),
    }),
    getCustomFileTranscriptions: builder.query({
      query: (params) => {
        if(params?.callId){
          const callIdParam = params?.callId ? `&call_id=${params?.callId}` : '';
          return `/stt/transcriptions_audio?${callIdParam}`
        }
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { 
  useGetAudioRecordsQuery, 
  useGetAudioRecordTranscriptionQuery, 
  useGetAudioRecordTranscriptionsQuery, 
  useGetProjectsQuery, 
  useGetPilotsQuery, 
  useGetAccessCodesQuery,
  useGetAgentsQuery,
  useGetStatisticsQuery,
  useGetUserFilesListQuery,
  useDeleteUserFileMutation,
  useTranscribeUserFileMutation,
  useGetCustomFileTranscriptionsQuery,
  useAddMessageMutation,
} = audioApi;
