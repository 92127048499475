import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import { useSelector } from "react-redux";
import { isLoggedIn } from './app/utils/reducers/authReducer';
import { LoginPage, MainLayout, LandingPage } from './app/components';

function App() {
  const loggedIn = useSelector(isLoggedIn);
  // console.log("App.js: loggedIn: ", loggedIn);
  // useRefreshTokenTimer();
  return loggedIn ? <MainLayout /> : <GoogleReCaptchaProvider reCaptchaKey={"6LeQ4O4nAAAAACFHMK4pXCKfZNaxH7Xs7tj-OWtW"}><LoginPage /></GoogleReCaptchaProvider>;
  // return <LandingPage />;
  // return loggedIn ? <MainLayout /> : <LoginPage />;
}

export default App;