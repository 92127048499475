import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Row, Col, Upload, message, Table, Tooltip, Space, Button, Popconfirm, Progress } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DemoAudioPage from './DemoAudioPage';
import { useGetUserFilesListQuery, useDeleteUserFileMutation, useTranscribeUserFileMutation } from '../../utils/api/audioApi';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const { Dragger } = Upload;
const draggerProps = {
  name: 'file',
  withCredentials: true,
  accept: 'audio/*',
  multiple: true,
  action: `${API_BASE_URL}/stt/upload_audio`,
  progress: { 
      size: 'small', 
      showInfo: true, 
      type: 'line',
      format: percent => `${percent.toFixed(1)}%`,
    },
};

const DemoPage = () => {
  const { t } = useTranslation();
  const [tableFilesList, setTableFilesList] = useState();
  const [currentFileId, setCurrentFileId] = useState();
  const [taskId, setTaskId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data, error, isLoading, isSuccess, refetch } = useGetUserFilesListQuery();
  const [deleteFile, { isLoading: isDeleteFileLoading }] = useDeleteUserFileMutation();
  const [transcribeFile, { isLoading: isTranscribeFileLoading }] = useTranscribeUserFileMutation();

  useEffect(() => {
    setTableFilesList(data?.files);
  }, [data?.files]);

  
  // useEffect(() => {
  //   console.log('files', files?.messages);
  // }, [files?.messages]);



  const handleOnChange = (info) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setTableFilesList([{name: info.file.name, id: info.file.uid, path: info.file.response.path, key: info.file.uid}, ...tableFilesList]);
        // message.success(`${info.file.name} file uploaded successfully.`);
        refetch();
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    };

  
  const handleDelete = async (fileId) => {
    try {
      await deleteFile(fileId).then((res) => {
        refetch();
      }
      );
    } catch (error) {
      console.log(error);
  }};

  
  const handleTranscribe = async (fileId) => {
    // setCurrentFileId(fileId);
    try {
      await transcribeFile(fileId).then((res) => {
        const tt = tableFilesList.map(item => {
          if (item.id === res?.data?.id) {
            return {...item, duration_transcribed: 1};
          } else {
            return item;
          }
          
      });
        refetch();
        // setTableFilesList(tt);
        // setCurrentFileId(fileId);
      }
      );
    } catch (error) {
      console.log(error);
  }};

  const handleModalOpen = (fileId) => {
    // refetch();
    setCurrentFileId(fileId);
    setIsModalVisible(true);
  }

  const handleModalClose = () => {
    setCurrentFileId(null);
    setIsModalVisible(false);
  };

  return (
    <>
      <Row style={{margin: '12px'}}>
          <Col span={18} style={{padding: '12px'}}>
            <Table 
              size='small'
              dataSource={tableFilesList}
              loading={false}
              pagination={false}
              columns={[
                {
                  title: t('File Name'),
                  dataIndex: 'name',
                },
                { title: t('Upload date'), dataIndex: 'upload_date', render: (date) => dayjs.utc(date).format('YYYY-MM-DD  HH:mm:ss'), sorter: false},
                { title: t('Duration'), dataIndex: 'duration', render: (text) => <Tooltip title={`${text} ${t('s')}`}>{secondsToTimeFormat(text)}</Tooltip>, sorter: false },
                {
                  title: t('Transcription'),
                  render: (_, record) => (

                    record && record?.duration_transcribed === record?.duration ?
                    <Button 
                      onClick={() => {
                        handleModalOpen(record.id);
                      }}
                      style={{minWidth: '170px', maxWidth: '170px'}} 
                      type='primary'>
                      {t("Open")}
                    </Button>
                    :
                    <>
                    {record?.duration_transcribed ?
                      <Progress 
                        style={{minWidth: '170px', maxWidth: '170px'}} 
                        percent={parseInt(record?.duration_transcribed/record?.duration*100)} 
                        size="small" 
                      />
                      :
                      <Button 
                        style={{minWidth: '170px', maxWidth: '170px'}} 
                        type='dashed' 
                        onClick={() => handleTranscribe(record.id)} 
                        loading={record?.id === currentFileId && isTranscribeFileLoading }
                        >
                        {t("Transcribe")}
                      </Button>
                      }
                    </>
                  ),
                },
                {
                  title: t('Actions'),
                  key: 'actions',
                  render: (_, record) => (
                    <Popconfirm 
                        title={t("Sure to delete?")} 
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                        >
                      <Button type="primary" danger icon={<DeleteOutlined />} loading={
                        isDeleteFileLoading && record?.id === data?.files?.id
                        }>
                      </Button>
                    </Popconfirm>
                  ),
                },
            ]} />
          </Col>
          <Col span={6} style={{padding: '12px'}}>
              <Dragger 
                style={{maxHeight: '250px', minHeight: '250px'}}
                {...draggerProps} 
                onChange={handleOnChange}
                // onDrop={handleOnDrop}
                >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("Click or drag audio file to this area to upload")}</p>
                <p className="ant-upload-hint">
                  {t("wav or mp3 file are supported")}
                </p>
              </Dragger>
          </Col>
      </Row>
      {currentFileId &&
        <DemoAudioPage
            call_data={data?.files?.find((c) => c?.id === currentFileId)}
            callId={currentFileId}
            visible={isModalVisible}
            onCancel={handleModalClose}
            key={currentFileId}
        />
      }
    </>
  );
};

export default DemoPage;